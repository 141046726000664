import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { FC, Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BaseSchema } from 'yup';
import { apiClient } from '../../../api/apiClient';
import { ApprovedSignersEmail, PatchSubDocQuestionnaireReq } from '../../../api/payloads';
import { LoadingButton } from '../../../components/Buttons';
import { FormikStepValidationHelper } from '../../../components/FormikStepValidationHelper/FormikStepValidationHelper';
import { useAuth, useMaskedParams, useOpportunities, useUI } from '../../../hooks';
import { useSubDocs } from '../../../hooks/ContextHooks/useSubDocs';
import { createDocument } from '../../../services/document.service';
import { AccountType, DocumentTypes, InspiraFinancialTrustCustodianId, LAGO_BDC_ID } from '../../../utility/constants';
import { isNullish } from '../../../utility/misc.util';
import { maskRouteId } from '../../../utility/route-mask.util';
import { FormikSubmitFn } from '../../../utility/types';
import { AccreditedInvestor } from './Forms/AccreditedInvestor';
import { AMLVerification } from './Forms/AMLVerification';
import { DistributionInformation } from './Forms/DistributionInformation';
import { DistributionInformationFormObserver } from './Forms/DistributionInformationFormObserver';
import { ElectronicDelivery } from './Forms/ElectronicDelivery';
import { FinancialAdvisor } from './Forms/FinancialAdvisor';
import { InvestorQuestionnaire } from './Forms/InvestorQuestionnaire';
import { PurchaseFundingInstruction } from './Forms/PurchaseFundingInstruction';
import { RepresentationOfResidence } from './Forms/RepresentationOfResidence';
import { getSubDocDto } from './Forms/subDocForm.util';
import { AccreditedInvestorFormSchema, AMLVerificationFormSchema, DistributionInformationFormSchema, ElectronicDeliveryFormSchema, FinancialAdvisorFormSchema, InvestorQuestionnaireFormSchema, PurchaseFundingInstructionFormSchema, RepresentationOfResidenceFormSchema, ReviewStepFormSchema, SubDocsFormValues, SupplementalDataFormSchema } from './Forms/subDocsFormSchema';
import { SubDocsReview } from './Forms/SubDocsReview';
import { SupplementalData } from './Forms/SupplementalData';

type TStep = {
  label: string,
  component: ReactElement,
  schema?: BaseSchema,
  optional?: boolean,
}

type SubDocsStepperProps = {
  initialValues: SubDocsFormValues,
  initialStep?: number,
}

const getSteps = (isEntity: boolean, isTrustAccount: boolean, isInspiraIra: boolean): TStep[] => {
  const supplementalDataStep: TStep = {
    label: "Supplemental Data",
    component: <SupplementalData />,
    schema: SupplementalDataFormSchema(isTrustAccount),
  };

  const purchaseFundingInstructionStep: TStep = {
    label: "IRA Purchase Instruction",
    component: <PurchaseFundingInstruction />,
    schema: PurchaseFundingInstructionFormSchema,
  };

  return [ 
    {
      label: "Investor Questionnaire",
      component: <InvestorQuestionnaire />,
      schema: InvestorQuestionnaireFormSchema,
    },
    {
      label: "Accredited Investor",
      component: <AccreditedInvestor />,
      schema: AccreditedInvestorFormSchema,
    },
    {
      label: "Financial Advisor Contact",
      component: <FinancialAdvisor />,
      schema: FinancialAdvisorFormSchema,
    },
    {
      label: "Representation of Residence",
      component: <RepresentationOfResidence />,
      schema: RepresentationOfResidenceFormSchema
    },
    {
      label: "Distribution Information",
      component: <DistributionInformation />,
      schema: DistributionInformationFormSchema,
    },
    ...(isEntity ? [supplementalDataStep] : []),
    {
      label: "Electronic Delivery",
      component: <ElectronicDelivery/>,
      schema: ElectronicDeliveryFormSchema,
    },
    ...(isInspiraIra ? [purchaseFundingInstructionStep] : []),
    {
      label: "AML Verification",
      component: <AMLVerification />,
      schema: AMLVerificationFormSchema,
    },
    {
      label: "Review",
      component: <SubDocsReview />,
      schema: ReviewStepFormSchema,
    }
  ];
}

export const SubDocsStepper: FC<SubDocsStepperProps> = ({initialValues, initialStep}: SubDocsStepperProps) => {
  const [activeStep, setActiveStep] = useState(initialStep ?? 0);
  const {setError, setLoading, setSuccess} = useUI();
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {attachmentsToBeDetached,setAttachmentsToBeDetached,subDocsAccount,subDocsTransactionId, subDocsClientId, transactionAccreditationCompleted, userIsApprovedSigner, subDocsClient} = useSubDocs();
  const {subDocId, investmentId} = useMaskedParams();
  const {investmentId: maskedInvestmentId} = useParams();
  const isLagoBdc = +investmentId === LAGO_BDC_ID;

  const steps = useMemo(() => {
    const isEntity = subDocsAccount?.account_type_id === AccountType.Entity;
    const isTrust = !!subDocsAccount?.trust;
    const isIRA = subDocsAccount?.account_type_id === AccountType.IRA;
    const isInspiraIra = isIRA && subDocsAccount?.custodian_id === InspiraFinancialTrustCustodianId;
    return getSteps(isEntity, isTrust, isInspiraIra) 
  }, [subDocsAccount])

  const isFinalStep = activeStep === steps.length - 1;
  
  useEffect(() => {
    const path = location.pathname;
    const stepName = steps[activeStep].label.toLowerCase().replace(/\s/g, '-');
    navigate(`${path}?step=${stepName}`, {replace: true});

  }, [activeStep, navigate, steps, location.pathname])
  
  const { opportunitiesMap } = useOpportunities();
  const opportunity = useMemo(() => opportunitiesMap[+investmentId!] ?? {}, [investmentId, opportunitiesMap]);
  const {user} = useAuth()

  const handleSave = useCallback(async (values: SubDocsFormValues, currentStep: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined,) => void) => {
    if (!subDocId || !subDocsClientId) {
      setError("Oops, something went wrong. Please try again later")
      return;
    }
    let isSaved = false;

    try {
      for (let index in values.ownerIdDocuments) {
        const ownerData = values.ownerIdDocuments[index]
        const hasHasOwnerImageFileUpload = !isNullish(ownerData.idImageFile) && ownerData.idImageFile instanceof File && !isNullish(ownerData.expirationDate);

        if (hasHasOwnerImageFileUpload && isNullish(ownerData.imageId)) {
          const file = ownerData.idImageFile as File;

          const documentId = await createDocument(file, {
            customerId: subDocsClientId,
            fileName: file.name,
            documentTypeId: DocumentTypes.Identification,
          });

          await apiClient.post('/documents/customers-documents/:p0/contact/:p1', {
            routeParams: [subDocsClientId, ownerData.contactId],
            data: {
              expirationDate: ownerData.expirationDate!.toISOString(),
              documentId,
            }
          });
          setFieldValue(`ownerIdDocuments[${index}].imageId`, documentId)
        }
      }

      for (let index in values.supportingDocuments) {
        const document = values.supportingDocuments[index];

        if (!isNullish(document?.supportingDocumentFile) && document.supportingDocumentFile instanceof File) {
          const file = document.supportingDocumentFile;
          const supportingDocumentId = await createDocument(file, {
            customerId: subDocsClientId,
            fileName: file.name,
            documentTypeId: DocumentTypes.Other,
          });

          await apiClient.post('/documents/subscription-documents/:p0/attachments', {
            data: { documentId: supportingDocumentId },
            routeParams: [subDocId!]
          });

          setFieldValue(`supportingDocuments[${index}].documentId`, supportingDocumentId)
          setFieldValue(`supportingDocuments[${index}].documentName`, file.name)
          setFieldValue(`supportingDocuments[${index}].supportingDocumentFile`, null)
        }
      }

      const routeParams = [+subDocId!];
      const dto = getSubDocDto(values, currentStep);
      const request: PatchSubDocQuestionnaireReq = { 
        subDoc: dto,
        detachedDocumentIds: attachmentsToBeDetached,
        isComplete: false,
      }

      await apiClient.patch('/investors/sub-doc/:p0', { data: request, routeParams });

      setAttachmentsToBeDetached([]);

      setSuccess("Progress saved");
      isSaved = true;
    } catch (e) {
      setError("Something went wrong. Please try again later");
      isSaved = false;
    } finally {
      setIsSaving(false);
      return isSaved;
    }
  }, [attachmentsToBeDetached, setAttachmentsToBeDetached,subDocId, setError, setSuccess, subDocsClientId])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit: FormikSubmitFn<SubDocsFormValues> = async (values, props) => {
    const { setSubmitting, setFieldValue} = props
    if (!subDocId || !subDocsTransactionId || !subDocsAccount) {
      setError("Oops, something went wrong. Please try again later")
      return;
    }

    try {
      if (activeStep < steps.length - 1 ) {
        const isSaved = await handleSave(values, activeStep + 1, setFieldValue);
        if (!isSaved) {
          return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo(0,0); // Note: If form steps get a navigation route, then can remove this since router will handle scroll restoration
        return;
      } else {
        if (isLagoBdc) {
          return;
        }

        const dto = getSubDocDto(values, activeStep);
        const request: PatchSubDocQuestionnaireReq = { 
          subDoc: dto,
          detachedDocumentIds: attachmentsToBeDetached,
          isComplete: true,
        };

        const routeParams = [+subDocId!];
        await apiClient.patch('/investors/sub-doc/:p0', { data: request, routeParams });
        setAttachmentsToBeDetached([])

        const payload: ApprovedSignersEmail = {
          customerId: subDocsClientId!,
          firmId: values?.firm,
          subscription_deadline: opportunity?.subscription_deadline,
          authUser: user?.id,
          transactionId: subDocsTransactionId.toString(),
          account_name: subDocsAccount?.account_name,
          entity_name: opportunity?.name
        }

        await apiClient.post('/docusign/sub-doc', {data: {questionnaireId: +subDocId}})
        if (!transactionAccreditationCompleted) {
          await apiClient.post('/professionals/approved-signers/email', {data: payload})
        } 
        
        if (!transactionAccreditationCompleted && userIsApprovedSigner) {
          const maskedClientId = maskRouteId(subDocsClientId);
          const maskedAccountId = maskRouteId(subDocsAccount.id);
          const maskedTransactionId = maskRouteId(subDocsTransactionId);
          
          navigate(`/opportunities/${maskedInvestmentId}/client/${maskedClientId}/accredited/${maskedAccountId}/transactions/${maskedTransactionId}`, {replace: true})
        } else {
          navigate(`/opportunities/${maskedInvestmentId}/bdc-interest-list`, {replace: true})
        }
      }
    } catch (e) {
      setError("Something went wrong. Please try again later")
      setLoading(false)
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ width: '100%', minWidth: 800 }}>
      <Stepper 
        activeStep={activeStep} 
        sx={
          activeStep <= 3 ? 
          {width: '100%', justifyContent: 'space-between'}
          :
          {width: '100%',}
        } 
        connector={<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><ChevronRightIcon sx={{color: 'grey',fontSize: 30}}/></Box>}
      >
        {steps.map(step => {
          const stepProps: { completed?: boolean } = {};
          
          return (
            <Step key={step.label} {...stepProps} sx={{position: 'relative'}}>
              <StepLabel>
                <Collapse orientation="horizontal" in>{step.label}</Collapse>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Fragment>
        {activeStep === steps.length ? (
        <Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          <Formik
            initialValues={initialValues}
            validationSchema={steps[activeStep].schema}
            onSubmit={handleSubmit}
          >{({isValid, values, setFieldValue, isSubmitting}) => (
            <Form>
              <FormikStepValidationHelper activeStep={activeStep}/>
              <DistributionInformationFormObserver />
              <Box sx={{ pt: 4 }}>
                {steps[activeStep].component}
              </Box>
              {(isLagoBdc && isFinalStep) && (
                <Typography variant='body1' color='error'>
                  Investor Documents will not be sent to potential investors until after the BDC conversion,
                  expected on 3/3. Please check back then to send documents to your client for signature.
                </Typography>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
                <Button
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{'.MuiButton-root:not(:last-child)': { mr: 2}}}>
                  <LoadingButton 
                    loading={isSaving} 
                    disabled={!isValid || isSubmitting} 
                    variant='outlined'
                    onClick={async () => {
                        setIsSaving(true)
                        await handleSave(values, activeStep, setFieldValue)
                        navigate('../../bdc-interest-list', {relative: 'path'})
                    }}
                  >
                    Save & Exit
                  </LoadingButton>
                  <LoadingButton 
                    loading={isSubmitting} 
                    type='submit' 
                    disabled={(isLagoBdc && isFinalStep) || !isValid || !subDocsClient?.address}
                  >
                    {activeStep === steps.length - 1 ? 'Send to Investor' : 'Save & Continue'}
                  </LoadingButton>
                </Box>
              </Box>
            </Form>
          )}
          </Formik>
        </Fragment>
      )}
      </Fragment>
    </Box>
  );
}