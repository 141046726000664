import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { FC, ReactNode } from "react";
import { CornerCloseButton } from "../Buttons";

type buttonColor= "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit";

type ConfirmationDialogProps = Omit<DialogProps, 'onClose'> & 
  { 
    handleClose: (success?: boolean) => void, 
    titleNode?: ReactNode, 
    yesButtonLabel?: string,
    yesButtonColor?: buttonColor, 
    noButtonLabel?: string,
    message?: string,
    textAlign?: string,
  }

//TODO: Consider adding an optional "Cancel" button for cases where "No" and "Cancel" 
// should have different behaviors

export const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  let {titleNode, yesButtonLabel, yesButtonColor, noButtonLabel, handleClose, message, textAlign, ...rest} = props;
  titleNode = titleNode ?? "Are you sure?"
  yesButtonLabel = yesButtonLabel ?? "Yes";
  yesButtonColor = yesButtonColor ?? "primary";
  noButtonLabel = noButtonLabel ?? "No";
  textAlign = textAlign ?? "center";

  return (
    <Dialog onClose={() => handleClose} {...rest} sx={{display: 'flex',flexDirection: 'column', justifyContent: 'space-evenly', height: '100%'}}>
      <DialogTitle textAlign='center' sx={{fontSize: '1.5rem'}}>{titleNode}</DialogTitle>
      {!!message && <DialogContent sx={{textAlign, px: 4}}>{message}</DialogContent>}
      <DialogActions sx={{display: 'flex', justifyContent: 'center', pb: 3, gap: 2}}>
        <Button onClick={() => handleClose(false)} size="large" variant="outlined">{noButtonLabel}</Button>
        <Button color={yesButtonColor} onClick={() => handleClose(true)} size="large">{yesButtonLabel}</Button>
      </DialogActions>
      <CornerCloseButton onClick={() => handleClose(false)} />
    </Dialog>
  )
} 