import { DistributionInstructionStatus } from "../utility/constants";
import { ApprovedSignersEmail, BatchUpdateNotificationReq, DeleteInterestedPartyReq, GetDistributionInstructionEnvelopeStatusResp, GetGpUpdatesResp, GetInterestedPartiesResp, GetProfessionalNotificationResp, GetProfessionalResp, GetProfessionalsByAdvisoryResp, GetSubDocEnvelopeStatusResp, GetUserResp, PatchInterestedPartyReq, PatchProfessionalReq, PatchVoidReq, PatchVoidResp, PostGrantAccessToHouseholdsResp, PostGrantProfessionalAccessToClientsReq, PostInterestedPartiesReq, PostNewProfessionalReq, RegisterAdvisorVisionUser } from "./payloads";
import { AccountDefaultInstruction, AccountTypesResp, CognitoUserStatus, DeletePhoneNumberReq, DeletePhoneNumberResp, GetCustodianResp, GetCustomerResp, GetDistributionInstructionsByAccountResp, GetDuplicateContactResp, GetNewClientFieldValuesResp, GetPhoneOptionsResp, GetStateResp, ModifyCognito, PatchContactReq, PatchCustomerAddressReq, PatchCustomerAddressResp, PatchCustomerReq, PatchCustomerResp, PatchPhoneNumberReq, PostAccountInstructionRelationReq, PostAccountInstructionRelationResp, PostContactReq, PostContactResp, PostCustomerAccountReq, PostCustomerDistributionInstructionReq, PostCustomerDistributionInstructionResp, PostCustomerEntityReq, PostCustomerEntityResp, PostCustomerReq, PostCustomerResp, PostNewCustomerDistributionInstructionReq, PostPhoneNumbersReq, RegisterVisionUser, ResetPassword } from "./payloads/customer.payload";
import { GetAccreditedInvestorQuestionsResp, GetCustomerDocumentsPayload, GetCustomerEntityDocumentsPayload, GetDocumentPayload, GetK1CheckListPayload, PostBulkDownload, PostContactIdentificationReq, PostCustomerDocumentReq, PostCustomerDocumentResp, PostCustomerEntityDocumentRequest, PostCustomerEntityDocumentResponse, PostDistributionInstructionDocumentReq, PostDistributionInstructionDocumentResp, PostDocumentReq, PostDocumentResp, PostGenerateCustomerDocumentUploadUrlReq, PostGenerateCustomerDocumentUploadUrlResp, PostSupportingDocumentsReq, PostSupportingDocumentsResp } from "./payloads/documents.payload";
import { GetEventsByProfessionalResp } from "./payloads/events.payload";
import { AccreditedAnswers, AccreditedTransactionType, AccreditedVerification, GetAllInvestors, GetAllRegDInvestors, GetFundRaisingBDCs, GetInProgressSubDocQuestionnaireResp, GetInvestmentCompanyDetail, GetInvestmentCompanyDocumentsResp, GetInvestmentResp, GetWiringInstructions, InterestedClient, InvestorUpdate, PatchSubDocQuestionnaireReq, PositionType, PostInterestListPayload, PostInvestorResp, PostSubDocLogReq, PostSubDocQuestionnaireReq, RegDInterestedClient, SubscriptionAmountUpdate } from "./payloads/investments.payload";

const docusignRoutes = [
  '/docusign/sub-doc',
  '/docusign/sub-doc/:p0',
  '/docusign/sub-doc/void/:p0',
  '/docusign/sub-doc/resend/:p0',
  '/docusign/distribution-instruction',
  '/docusign/distribution-instruction/:p0/envelope-status',
  '/docusign/distribution-instruction/:p0/void',
] as const;

const documentsRoutes = [
  '/documents',
  '/documents/:p0',
  '/documents/bulk-download',
  '/documents/customers-documents/:p0',
  '/documents/customers-documents/:p0/contact/:p1',
  '/documents/customers-documents/:p0/entity/:p1',
  '/documents/customers-documents/:p0/distribution-instructions/:p1',
  '/documents/customers-documents/:p0/account/:p1/entity/:p2',
  '/documents/customers-documents/generate-signed-url',
  '/documents/k1checklist/:p0',
  '/documents/subscription-documents/:p0/attachments',
  '/documents/subscription-documents/accredited-investor-questions',
] as const;

export const customersRoutes = [
  '/customers/',
  '/customers/:p0',
  '/customers/:p0/address/:p1',
  '/customers/accounts',
  '/customers/accounts/types',
  '/customers/accounts/:p0/distribution-instructions',
  '/customers/:p0/distribution-instructions',
  '/customers/accounts/:p0/distribution-instructions/default', // Unused? 
  '/customers/accounts/:p0/distribution-instructions/:p1',
  '/customers/custodians',
  '/customers/entities',
  '/customers/new-client-field-values',
  '/customers/phone-options',
  '/customers/states',
  '/customers/contacts',
  '/customers/contacts/:p0',
  '/customers/contacts/duplicate',
  '/customers/contacts/:p0/phone-numbers',
  '/customers/investor-vision/register',
  '/customers/investor-vision/user/status',
  '/customers/investor-vision/modify',
  '/customers/investor-vision/reset-password',
] as const;

export const investmentsRoutes = [
  '/investors/:p0/distribution-instructions/:p1',
  '/investors/:p0/confirmed',
  '/investors/investment-companies/:p0/investors/:p1',
  '/investors/investment-companies/:p0/transactions',
  '/investors/investment-companies/:p0/investors',
  '/investors/accounts/:p0/transactions/investment-companies/:p1',
  '/investors/investment-companies/fundraising-bdcs/',
  '/investors/investment-companies/',
  '/investors/investment-companies/:p0',
  '/investors/investment-companies/:p0/documents',
  '/investors/investment-companies/:p0/wiring-instructions',
  '/investors/bdc-positions/:p0/transactions',
  '/investors/bdc-positions/:p0/distribution-instructions/:p1',
  '/investors/transactions/:p0/accreditations',
  '/investors/transactions/:p0/sub-doc',
  '/investors/sub-doc/:p0',
  '/investors/sub-doc/:p0/in-progress',
  '/investors/sub-doc/:p0/log',
  '/investors/accredited-verification-questions',
  '/investors/transactions/:p0',
  '/investors/bdc-prospects/:p0',
  '/investors/accredited-entity-questions'
] as const;

export const eventsRoutes = [
  '/events/by-professional/:p0',
  '/events/event-types',
] as const;

export const portfolioRoutes = [
  '/portfolio-companies/gp-updates/:p0'
] as const;

const professionalsRoutes = [
  '/professionals/',
  '/professionals/:p0',
  '/professionals/advisories/:p0/professionals',
  '/professionals/approved-signers/email',
  '/professionals/interested-parties',
  '/professionals/interested-parties/:p0',
  '/professionals/:p0/notifications',
  '/professionals/:p0/notifications/mark-as-read',
  '/professionals/:p0/notifications/clear',
  '/professionals/interested-parties/customers/:p0/professional/:p1',
  '/professionals/interested-parties/grant-access',
  '/professionals/advisor-vision-register',
] as const;

const usersRoutes = [
  '/advisor-vision/users/:p0',
] as const;

/** Union type of all microservice route strings */
export type ApiRoute =
  typeof documentsRoutes[number] |
  typeof customersRoutes[number] |
  typeof eventsRoutes[number] |
  typeof investmentsRoutes[number] |
  typeof portfolioRoutes[number] |
  typeof professionalsRoutes[number] |
  typeof usersRoutes[number] |
  typeof docusignRoutes[number];

const microServices = {
  [process.env.REACT_APP_CUSTOMERS_MICRO_URL || ""]: customersRoutes,
  [process.env.REACT_APP_DOCUMENTS_MICRO_URL || ""]: documentsRoutes,
  [process.env.REACT_APP_DOCUSIGN_MICRO_URL || ""]: docusignRoutes,
  [process.env.REACT_APP_EVENTS_MICRO_URL || ""]: eventsRoutes,
  [process.env.REACT_APP_INVESTORS_MICRO_URL || ""]: investmentsRoutes,
  [process.env.REACT_APP_PORTFOLIO_MICRO_URL || ""]: portfolioRoutes,
  [process.env.REACT_APP_PROFESSIONALS_MICRO_URL || ""]: professionalsRoutes,
  [process.env.REACT_APP_USERS_MICRO_URL || ""]: usersRoutes,
} as const;

function createUrlMap(baseUrl: string, routes: readonly string[]) {
  return routes.reduce((acc, route) => ({ ...acc, [route]: baseUrl }), {} as Record<typeof routes[number], string>);
}

/** 
 * Mapping of routes to microservice baseURL.
 * For development without a local proxy since all are behind one base URL when deployed 
 */
export const UrlMap = Object.entries(microServices).reduce((acc, [baseUrl, routes]) => {
  return { ...acc, ...createUrlMap(baseUrl, routes) };
}, {} as Record<ApiRoute, string>);

/**
 * Mapping of GET request routes to their response payload type
 * @note Eventually the union of these keys with the keys of other route-response maps will be used to derive the ApiRoute type
 */
export type GetRespPayloadTypeMap = {
  '/advisor-vision/users/:p0': GetUserResp,
  '/customers/:p0': GetCustomerResp,
  '/customers/accounts/:p0/distribution-instructions': GetDistributionInstructionsByAccountResp,
  '/customers/accounts/:p0/distribution-instructions/default': AccountDefaultInstruction[],
  '/customers/accounts/types': AccountTypesResp,
  '/customers/custodians': GetCustodianResp,
  '/customers/new-client-field-values': GetNewClientFieldValuesResp,
  '/customers/phone-options': GetPhoneOptionsResp,
  '/customers/states': GetStateResp,
  '/customers/contacts/duplicate': GetDuplicateContactResp
  '/documents/:p0': GetDocumentPayload,
  '/documents/customers-documents/:p0': GetCustomerDocumentsPayload,
  '/documents/customers-documents/:p0/account/:p1/entity/:p2': GetCustomerEntityDocumentsPayload,
  '/documents/k1checklist/:p0': GetK1CheckListPayload
  '/documents/subscription-documents/accredited-investor-questions': GetAccreditedInvestorQuestionsResp,
  '/docusign/sub-doc/:p0': GetSubDocEnvelopeStatusResp,
  '/docusign/distribution-instruction/:p0/envelope-status': GetDistributionInstructionEnvelopeStatusResp,
  '/events/by-professional/:p0': GetEventsByProfessionalResp,
  '/events/event-types': GetEventsByProfessionalResp,
  '/investors/accredited-verification-questions': AccreditedVerification[],
  '/investors/accredited-entity-questions': AccreditedVerification[]
  '/investors/investment-companies/:p0/investors': GetAllRegDInvestors,
  '/investors/investment-companies/:p0/transactions': GetAllInvestors,
  '/investors/investment-companies/fundraising-bdcs/': GetFundRaisingBDCs,
  '/investors/investment-companies/': GetInvestmentResp,
  '/investors/investment-companies/:p0': GetInvestmentCompanyDetail,
  '/investors/investment-companies/:p0/documents': GetInvestmentCompanyDocumentsResp,
  '/investors/investment-companies/:p0/wiring-instructions': GetWiringInstructions,
  '/investors/bdc-positions/:p0/transactions': PositionType,
  '/investors/sub-doc/:p0/in-progress': GetInProgressSubDocQuestionnaireResp,
  '/investors/subdoc/accredited/:p0': AccreditedAnswers, //TODO - Fix this inconsistent 'subdoc' vs 'sub-doc' naming
  '/portfolio-companies/gp-updates/:p0': GetGpUpdatesResp,
  '/professionals/:p0': GetProfessionalResp,
  '/professionals/advisories/:p0/professionals': GetProfessionalsByAdvisoryResp,
  '/professionals/interested-parties/:p0': GetInterestedPartiesResp,
  '/professionals/:p0/notifications': GetProfessionalNotificationResp,
}

/** Mapping of POST request routes to their request and response payload types */
export type PostTypeMap = {
  '/customers/': { req: PostCustomerReq, res: PostCustomerResp },
  '/customers/:p0/distribution-instructions': { req: PostNewCustomerDistributionInstructionReq, res: PostCustomerDistributionInstructionResp },
  '/customers/accounts': { req: PostCustomerAccountReq, res: PostCustomerEntityDocumentResponse },
  '/customers/accounts/:p0/distribution-instructions': { req: PostCustomerDistributionInstructionReq, res: PostCustomerDistributionInstructionResp },
  '/customers/accounts/:p0/distribution-instructions/:p1': { req: PostAccountInstructionRelationReq, res: PostAccountInstructionRelationResp },
  '/customers/contacts': { req: PostContactReq, res: PostContactResp },
  '/customers/contacts/:p0/phone-numbers': { req: PostPhoneNumbersReq, res: number[] },
  '/customers/entities': { req: PostCustomerEntityReq, res: PostCustomerEntityResp },
  '/customers/investor-vision/modify': { req: ModifyCognito, res: string },
  '/customers/investor-vision/register': { req: RegisterVisionUser, res: any },
  '/customers/investor-vision/user/status': { req: CognitoUserStatus, res: any },
  '/customers/investor-vision/reset-password': { req: ResetPassword, res: any },
  '/documents': { req: PostDocumentReq, res: PostDocumentResp },
  '/documents/customers-documents/:p0': { req: PostCustomerDocumentReq, res: PostCustomerDocumentResp },
  '/documents/customers-documents/:p0/distribution-instructions/:p1': { req: PostDistributionInstructionDocumentReq, res: PostDistributionInstructionDocumentResp },
  '/documents/customers-documents/:p0/contact/:p1': { req: PostContactIdentificationReq, res: never },
  '/documents/customers-documents/:p0/entity/:p1': { req: PostCustomerEntityDocumentRequest, res: PostCustomerEntityDocumentResponse },
  '/documents/customers-documents/generate-signed-url': { req: PostGenerateCustomerDocumentUploadUrlReq, res: PostGenerateCustomerDocumentUploadUrlResp }
  '/documents/subscription-documents/:p0/attachments': { req: PostSupportingDocumentsReq, res: PostSupportingDocumentsResp },
  '/documents/bulk-download': { req: PostBulkDownload, res: { message: string } },
  '/docusign/sub-doc': { req: { questionnaireId: number }, res: { envelopeId: string } },
  '/docusign/sub-doc/resend/:p0': { req: { questionnaireId: number }, res: { message: string } },
  '/docusign/distribution-instruction': { req: { distributionInstructionId: number }, res: { envelopeId: string } },
  '/investors/:p0/distribution-instructions/:p1': { req: { status: DistributionInstructionStatus }, res: { success: boolean } },
  '/investors/bdc-positions/:p0/distribution-instructions/:p1': { req: { status: DistributionInstructionStatus }, res: { success: boolean } },
  '/investors/transactions/:p0/accreditations': { req: AccreditedTransactionType, res: number[] },
  '/investors/transactions/:p0/sub-doc': { req: PostSubDocQuestionnaireReq, res: { subDocQuestionnaireId: number } },
  '/investors/investment-companies/:p0/transactions': { req: PostInterestListPayload, res: PostInvestorResp },
  /** @todo: Provide a consistent type for the response, e.g. match shape of records returned by Get Regd Interest List endpoint*/
  '/investors/investment-companies/:p0/investors': { req: PostInterestListPayload, res: unknown },
  '/investors/sub-doc/:p0/log': { req: PostSubDocLogReq, res: { message: string } },
  '/professionals/approved-signers/email': { req: ApprovedSignersEmail, res: string },
  '/professionals/interested-parties': { req: PostInterestedPartiesReq, res: number[] },
  '/professionals/': { req: PostNewProfessionalReq, res: { professionalId: number } },
  '/professionals/interested-parties/grant-access': { req: PostGrantProfessionalAccessToClientsReq, res: PostGrantAccessToHouseholdsResp },
  '/professionals/advisor-vision-register': { req: RegisterAdvisorVisionUser, res: any },
}

export type PatchTypeMap = {
  '/customers/:p0/address/:p1': { req: PatchCustomerAddressReq, res: PatchCustomerAddressResp }
  '/customers/contacts/:p0': { req: PatchContactReq, res: undefined },
  '/customers/contacts/:p0/phone-numbers': { req: PatchPhoneNumberReq, res: undefined },
  '/customers/:p0': { req: PatchCustomerReq, res: PatchCustomerResp },
  '/investors/:p0/confirmed': { req: { investorId: number }, res: { message: string } },
  '/investors/sub-doc/:p0': { req: PatchSubDocQuestionnaireReq, res: { subDocQuestionnaireId: number } },
  '/investors/transactions/:p0': { req: SubscriptionAmountUpdate, res: InterestedClient[] }
  '/investors/investment-companies/:p0/investors/:p1': { req: InvestorUpdate, res: RegDInterestedClient[] }
  '/docusign/sub-doc/void/:p0': { req: PatchVoidReq, res: PatchVoidResp },
  '/docusign/distribution-instruction/:p0/void': { req: PatchVoidReq, res: PatchVoidResp },
  '/professionals/:p0': { req: PatchProfessionalReq, res: { message: string } },
  '/professionals/interested-parties/customers/:p0/professional/:p1': { req: PatchInterestedPartyReq, res: { count: number } },
  '/professionals/:p0/notifications/mark-as-read': { req: BatchUpdateNotificationReq, res: { success: boolean } },
  '/professionals/:p0/notifications/clear': { req: BatchUpdateNotificationReq, res: { success: boolean } },
}

export type DeleteTypeMap = {
  '/customers/contacts/:p0/phone-numbers': { req: DeletePhoneNumberReq, res: DeletePhoneNumberResp }
  '/professionals/interested-parties': { req: DeleteInterestedPartyReq, res: { count: number } },
  '/investors/transactions/:p0': { req: any, res: any }
  '/investors/bdc-prospects/:p0': { req: any, res: any }
  '/investors/investment-companies/:p0/investors/:p1': { req: any, res: any }
}