import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, MenuItem } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Select } from "formik-mui";
import { FC, useCallback, useState } from "react";
import { mixed, number, object, string } from "yup";
import { apiClient } from "../../../../../api/apiClient";
import { AchInstructionModel, BrokerageInstructionModel, CheckInstructionModel, PostCustomerDistributionInstructionReq, WireInstructionModel } from "../../../../../api/payloads/customer.payload";
import { CornerCloseButton, LoadingButton } from "../../../../../components/Buttons";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog/ConfirmationDialog";
import { useAuth, useClients, useInit, useUI } from "../../../../../hooks";
import { useSubDocs } from "../../../../../hooks/ContextHooks/useSubDocs";
import { createDocument } from "../../../../../services/document.service";
import { DistributionInstructionType, DocumentTypes } from "../../../../../utility/constants";
import { invalidFileDataTest, largeFileTest, largeFileTestMessage } from "../../../../../utility/file.util";
import { FormikSubmitFn } from "../../../../../utility/types";
import ACHInstructionFields from "./ACHInstructionFields";
import BrokerageInstructionFields from "./BrokerageInstructionFields";
import CheckInstructionFields from "./CheckInstructionFields";
import WireInstructionFields from "./WireInstructionFields";
import { alphanumericRegex, alphanumericWithSpacesOnly, numericOnly } from "../../../../../utility/misc.util";

export type NewInstructionDialogProps = Omit<DialogProps, 'onClose'> & {
  handleClose: (distributionInstructionId?: number) => void
};

export type InstructionFormValues = {
  instructionType: "" | number
  wireInstructions: WireInstructionModel & { verificationDocument: any},
  achInstructions: AchInstructionModel & {voidedCheck: any}
  checkInstructions: CheckInstructionModel
  brokerageInstructions: BrokerageInstructionModel & { verificationDocument: any, financialInstitutionAddressId: string | number}
}

export const instructionFormSchema = object({
  instructionType: number().required("Please select an instruction type"),
  brokerageInstructions: object().shape({}).when('instructionType', {
    is: 4,
    then: schema => schema.shape({
      instructionName: string().required("Brokerage Instruction name is required"),
      custodianId: number().required("Custodian is required"),
      bankAccountName: string().required("Account name is required"),
      aba: string()
        .required("Routing number is required")
        .max(9, "Routing number should not exceed 9 digits")
        .matches(numericOnly, "Routing number should be numeric"),
      accountNumber: string()
        .required("Account number is required")
        .matches(alphanumericRegex, "Account number must be alphanumeric only"),
      financialInstitutionName: string().required("Financial institution name is required"),
      financialInstitutionAddressId: number().required("Financial institution address is required"),
      financialCity: string().required("City is required"),
      financialStateId: number().required("State is required"),
      ffc: string()
        .required("FFC is required")
        .matches(alphanumericWithSpacesOnly, "For Further Credit To must be alphanumeric only"),
      ffcAccountNumber: string()
        .required("FFC Account number is required")
        .matches(alphanumericRegex, "FFC Account Number must be alphanumeric only"),
      verificationDocument: mixed()
        .required("Verification Document is required")
        .test('invalidFileData', "Invalid file data", invalidFileDataTest)
        .test('fileSize', largeFileTestMessage, largeFileTest)
    })
  }),
  wireInstructions: object().shape({}).when('instructionType', {
    is: 1,
    then: schema => schema.shape({
      instructionName: string().required("Wire name is required"),
      bankAccountName: string().required("Account name is required"),
      aba: string()
        .required("Routing number is required")
        .max(9, "Routing number should not exceed 9 digits")
        .matches(numericOnly, "Routing number should be numeric"),
      accountNumber: string()
        .required("Account number is required")
        .matches(alphanumericRegex, "Account number must be alphanumeric only"),
      financialInstitutionName: string().required("Financial institution name is required"),
      financialStreetAddress1: string().required("Financial Institution Address is required"),
      financialStreetAddress2: string().optional(),
      financialCity: string().required("City is required"),
      financialStateId: number().required("State is required"),
      financialZip: string().min(5, "Zip Code must be at least 5 digits").required("Required"),
      ffc: string()
        .optional()
        .nullable()
        .matches(alphanumericWithSpacesOnly, "For Further Credit To must be alphanumeric only"),
      ffcAccountNumber: string()
        .optional()
        .nullable()
        .matches(alphanumericRegex, "FFC Account Number must be alphanumeric only"),
      verificationDocument: mixed()
        .required("Verification Document is required")
        .test('invalidFileData', "Invalid file data", invalidFileDataTest)
        .test('fileSize', largeFileTestMessage, largeFileTest)
    })
  }),
  checkInstructions: object().shape({}).when('instructionType', {
    is: 2,
    then: schema => schema.shape({
      instructionName: string().required("Check name is required"),
      payeeName: string().required("Payee name is required"),
      existingAddressId: number().nullable(),
      streetAddress1: string().required("Street Address is required"),
      streetAddress2: string().optional(),
      city: string().required("City is required"),
      stateId: number().required("State is required"),
      zip: string().min(5, "Zip Code must be at least 5 digits").required("Required") 
    })
  }),
  achInstructions: object().shape({}).when('instructionType', {
    is: 3,
    then: schema => schema.shape({
      instructionName: string().required("ACH name is required"),
      bankAccountName: string().required("Account name is required"),
      aba: string()
        .required("Routing number is required")
        .max(9, "Routing number should not exceed 9 digits")
        .matches(numericOnly, "Routing number should be numeric"),
      accountNumber: string()
        .required("Account number is required")
        .matches(alphanumericRegex, "Account number must be alphanumeric only"),
      ffc: string()
        .optional()
        .nullable()
        .matches(alphanumericWithSpacesOnly, "For Further Credit To must be alphanumeric only"),
      accountType: number().required("Account type is required"),
      voidedCheck: mixed().required("Required")
        .test('invalid-file-type', "File type is not supported", value => {
          if (!(value instanceof File)) { return true; }
          return ['application/pdf', 'image/jpeg', 'image/tiff', 'image/gif', 'image/png'].includes(value.type);
        })
        .test('invalidFileData', "Invalid file data", invalidFileDataTest)
        .test('fileSize', largeFileTestMessage, largeFileTest),
      })
  }),
});

export const NewInstructionDialog: FC<NewInstructionDialogProps> = (props) => {
  const {  maxWidth, fullWidth, handleClose, ...rest } = props;
  const { loading, setLoading, setError, setSuccess } = useUI()
  const {user} = useAuth();
  const {addressStates, setAddressStates} = useClients();
  const {subDocsAccount, subDocsClient, distributionInstructionsData, setDistributionInstructionsData} = useSubDocs();
  const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);

  const initialValues: InstructionFormValues = { 
    instructionType: DistributionInstructionType.ACH,
    wireInstructions: {
      instructionName: "",
      bankAccountName: "",
      aba: "",
      accountNumber: "",
      financialInstitutionName: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      stateId: "",
      zip: "",
      financialStreetAddress1: "",
      financialStreetAddress2: "",
      financialCity: "",
      financialStateId: "",
      financialZip: "",
      ffc: "",
      ffcAccountNumber: "",
      verificationDocument: null
    },
    achInstructions: {
      instructionName: "",
      bankAccountName: "",
      aba: "",
      accountNumber: "",
      accountType: "",
      ffc: "",
      voidedCheck: null
    },
    checkInstructions: {
      instructionName: "",
      payeeName: "",
      existingAddressId: null,
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      stateId: "",
      zip: "", 
    },
    brokerageInstructions: {
      instructionName: "",
      custodianId: "",
      bankAccountName: "",
      aba: "",
      accountNumber: "",
      financialInstitutionName: "",
      financialInstitutionAddressId: "",
      financialCity: "",
      financialStateId: "",
      ffc: "",
      ffcAccountNumber: "",
      verificationDocument: null
    }
  };

  const handleSubmit: FormikSubmitFn<InstructionFormValues> = async (values) => {
    if (!values.instructionType || !subDocsAccount || !subDocsClient || !user){
      setError("Oops, something went wrong. Please try again later")
      return
    }
    setLoading(true);
    try {
      const createDistributionInstructionData: PostCustomerDistributionInstructionReq = {
       instructionType: values.instructionType,
      }
      if (values.instructionType === DistributionInstructionType.Wire) {
        createDistributionInstructionData['wireInstructions'] = {
          instructionName: values.wireInstructions.instructionName,
          bankAccountName: values.wireInstructions.bankAccountName,
          aba: values.wireInstructions.aba,
          accountNumber: values.wireInstructions.accountNumber,
          financialInstitutionName: values.wireInstructions.financialInstitutionName,
          financialStreetAddress1: values.wireInstructions.financialStreetAddress1,
          financialStreetAddress2: values.wireInstructions.financialStreetAddress2,
          financialCity: values.wireInstructions.financialCity,
          financialStateId: values.wireInstructions.financialStateId,
          financialZip: values.wireInstructions.financialZip,
          ffc: values.wireInstructions.ffc,
          ffcAccountNumber: values.wireInstructions.ffcAccountNumber,
          /* 
            STOP GAP: This object had been using field names that should map to the generic 'instruction' address rather than financial institution address.
            On backend they were correctly used for creating the financial institution address, but this has been causing confusion, so have updated to use
            the disambiguated "financialInstitution" address field names. Also, the backend is being updated to handle accepting both addresses, 
            but until the UI is updated it is still going to rely on a previous stop gap that automatically copies the Household address on the backend. 
            Given this, we're just going to pass empty strings for the generic address in this version of the create instruction flow
            as the backend for this won't be reading from these yet 
          */
          streetAddress1: "",
          streetAddress2: "",
          city: "",
          stateId: "",
          zip: "",
        }
      } else if (values.instructionType === DistributionInstructionType.ACH) {
        createDistributionInstructionData['achInstructions'] = {
          instructionName: values.achInstructions.instructionName,
          bankAccountName: values.achInstructions.bankAccountName,
          aba: values.achInstructions.aba,
          accountNumber: values.achInstructions.accountNumber,
          accountType: values.achInstructions.accountType, 
          ffc: values.achInstructions.ffc,
        }
      } else if (values.instructionType === DistributionInstructionType.Check) {
        createDistributionInstructionData['checkInstructions'] = {
          instructionName: values.checkInstructions.instructionName,
          payeeName: values.checkInstructions.payeeName,
          existingAddressId: values.checkInstructions.existingAddressId = null, //Flag for removal, but keeping for now since I believe new instruction flow is using it
          streetAddress1: values.checkInstructions.streetAddress1,
          streetAddress2: values.checkInstructions.streetAddress2,
          city: values.checkInstructions.city,
          stateId: values.checkInstructions.stateId,
          zip: values.checkInstructions.zip,
        }
      } else if (values.instructionType === DistributionInstructionType.Brokerage) {
        createDistributionInstructionData['brokerageInstructions'] = {
          instructionName: values.brokerageInstructions.instructionName,
          custodianId: values.brokerageInstructions.custodianId,
          bankAccountName: values.brokerageInstructions.bankAccountName,
          aba: values.brokerageInstructions.aba,
          accountNumber: values.brokerageInstructions.accountNumber,
          financialInstitutionName: values.brokerageInstructions.financialInstitutionName,
          financialCity: values.brokerageInstructions.financialCity,
          financialStateId: values.brokerageInstructions.financialStateId,
          ffc: values.brokerageInstructions.ffc,
          ffcAccountNumber: values.brokerageInstructions.ffcAccountNumber,
        }
      }

      let documentId = await handleDocumentUpload(subDocsClient.id, values);

      const { distributionInstructionId } = await apiClient.post("/customers/accounts/:p0/distribution-instructions", { 
        data: createDistributionInstructionData,
        routeParams: [subDocsAccount.id],
      });

      if (documentId) { 
        await apiClient.post("/documents/customers-documents/:p0/distribution-instructions/:p1", {
          routeParams: [subDocsClient.id, distributionInstructionId],
          data: { documentId }
        }) 
      }

      setLoading(false);
      if (distributionInstructionId) { //INVESTIGATE: Is this if-bock needed?
        setSuccess("Successfully added Distribution Instruction")
        const distributionInstructionsResp = await apiClient.get('/customers/accounts/:p0/distribution-instructions', { routeParams: [subDocsAccount.id]});
        setDistributionInstructionsData(distributionInstructionsResp);
        handleClose(distributionInstructionId);
      }
    } catch (e) {
      setError("Oops, something went wrong. Please try again later")
      setLoading(false)
    }
  };

  const init = useCallback(async () => {
    if (addressStates == null) {
      const states = await apiClient.get("/customers/states");
      setAddressStates(states);
    }
    if (distributionInstructionsData == null) {
      const distributionInstructionsResp = await apiClient.get('/customers/accounts/:p0/distribution-instructions', { routeParams: [subDocsAccount?.id!]});
      setDistributionInstructionsData(distributionInstructionsResp);
    }
  }, [addressStates,distributionInstructionsData, setAddressStates, subDocsAccount,setDistributionInstructionsData])

  useInit(init);

  const renderAppropriateFields = useCallback((values: InstructionFormValues) => {
    switch(values.instructionType) {
      case DistributionInstructionType.Wire:
        return <WireInstructionFields />;
      case DistributionInstructionType.ACH:
        return <ACHInstructionFields   />;
      case DistributionInstructionType.Check:
        return <CheckInstructionFields   />;
      case DistributionInstructionType.Brokerage:
        return <BrokerageInstructionFields />;
      default:
        return null
    }
  },[])

  const closeWarningDialog = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, confirmed?: boolean, ) => {
    if (confirmed) {
      setFieldValue("instructionType", DistributionInstructionType.Check)
    }
    setWarningDialogOpen(false)
  }

  return (
    <Dialog onBlur={() => {}} PaperProps={{ sx: { minWidth: '1000px', } }} fullWidth maxWidth={maxWidth} {...rest}>
      <DialogTitle>
        Add New Distribution Instructions
        <CornerCloseButton onClick={() => handleClose()}></CornerCloseButton>
      </DialogTitle>
        <Formik initialValues={initialValues} validationSchema={instructionFormSchema} onSubmit={handleSubmit}>
          {({ isValid, dirty, errors, values, setFieldValue }) => (
            <Form>
              <DialogContent style={{ paddingTop: '0.5rem',}}>
                <ErrorMessage  name="error"/>
                <Field
                  formControl={{required: true, sx:{width: 'calc(50% - 8px)'}}}
                  component={Select}
                  label="Instruction Type"
                  name="instructionType"
                  onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
                  onChange={(e: any) => {
                    if (e.target?.value === DistributionInstructionType.Check) {
                      e.preventDefault()
                      setWarningDialogOpen(true);
                    } else {
                      setFieldValue("instructionType", e.target?.value)
                    }
                  }}
                  >
                  {distributionInstructionsData?.distributionInstructionTypes.map(instruction => <MenuItem key={instruction.id} value={instruction.id}>{instruction.name}</MenuItem>)}
                </Field>
                {renderAppropriateFields(values)}
              </DialogContent>
              <DialogActions>
                <Button color="inherit" onClick={() => handleClose()} sx={{ mr: 1 }}>
                  Cancel
                </Button>
                <LoadingButton disabled={!isValid && dirty} color='info' type='submit' loading={loading}>
                  Submit
                </LoadingButton>
                </DialogActions>
                <ConfirmationDialog open={warningDialogOpen} title="Please Confirm" message="By selecting the Check option, you understand that your receipt of distributions from your investment will be sent via paper check and therefore will be delayed relative to electronic delivery." yesButtonLabel="Confirm" noButtonLabel="Cancel" handleClose={(success) => {
                  closeWarningDialog(setFieldValue, success)}} />
            </Form>
          )}
        </Formik>
    </Dialog>
  )
}


function handleDocumentUpload(clientId: number, values: InstructionFormValues) {
  const isRequiresDocument = [
    DistributionInstructionType.Wire,
    DistributionInstructionType.ACH,
    DistributionInstructionType.Brokerage
  ].includes(values.instructionType as number);

  if (!isRequiresDocument) {
    return;
  }

  const file =
    values.instructionType === DistributionInstructionType.ACH ? values.achInstructions.voidedCheck :
      values.instructionType === DistributionInstructionType.Wire ? values.wireInstructions.verificationDocument :
        values.brokerageInstructions.verificationDocument;

  if (!(file instanceof File)) {
    throw new Error("Could not resolve uploaded file.");
  }

  const documentTypeId = values.instructionType === DistributionInstructionType.ACH ? DocumentTypes.VoidedCheck : DocumentTypes.WireVerificationDocument;

  return createDocument(file, {
    fileName: file.name,
    customerId: clientId,
    documentTypeId,
  });
}