import { CancelOutlined } from "@mui/icons-material";
import { IconButton, IconButtonProps, styled } from "@mui/material";
import { FC } from "react";

const CornerIconButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 10000,
});

export const CornerCloseButton: FC<IconButtonProps> = (props) => {
  return <CornerIconButton aria-label='close' {...props}><CancelOutlined/></CornerIconButton>
}