import { LocationOn, Search } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Container, Dialog, Icon, IconButton, InputAdornment, Link as MuiLink, Paper, TableContainer, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Professional } from "../../../api/payloads";
import { ContentBackground } from "../../../components/ContentBackground/ContentBackground";
import { DataSourceTable } from "../../../components/DataSourceTable";
import { AddressLine } from "../../../components/Typography/AddressLine";
import { useAuth, useInit, useProfessionals, useUI } from "../../../hooks";
import { apiClient } from "../../../api/apiClient";
import { ErrorContactEmail } from "../../../components/ErrorContactEmail/ErrorContactEmail";
import AdminAddProfDialog from "../Dialogs/AdminAddProfDialog";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { ResourceReassignmentConfirmationDialog } from "../../../components/ConfirmationDialog/ResourceReassignmentConfirmationDialog";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog/ConfirmationDialog";

/** 
 * To help track the clients that truly require reassign:
 * "needReassignCount" = number of clients where the to-be-deleted professional is their ONLY professional from the same advisory.
 */
type ReassignData = {
  needReassignCount: number;
  reassignOptions: { value: string; label: string }[];
};

type OpenDeleteDialogFn = (professionalId: number) => void;
type RegisterProfessionalFn = (row: Professional) => void;

const displayStatusFn = (v: boolean, row: Professional, handleOpenRegisterDialog: RegisterProfessionalFn, professional: Professional) => {
  const userEmailDomain = row.email.split('@')[1].toLowerCase();
  const adminEmailDomain = professional.email.split('@')[1].toLowerCase();

  return v ? (
    <Typography
      sx={{
        display: "inline-block",
        padding: "2px 12px",
        borderRadius: "6px",
        backgroundColor: "#e8f5e9",
        color: "#2E7D32",
        borderColor: "success.dark",
        fontSize: "14px",
      }}
    >
      Registered
    </Typography>
  ) : (
    <>
      <Typography
        sx={{
          display: "inline-block",
          padding: "2px 8px",
          borderRadius: "6px",
          backgroundColor: "#fbe8e6",
          color: "#C62828",
          borderColor: "error.dark",
          fontSize: "14px",
        }}
      >
        Not Registered
      </Typography>

      <Tooltip
        title={
          userEmailDomain !== adminEmailDomain
            ? `Cannot register outside of domain ${adminEmailDomain}`
            : "Register Professional"
        }
        arrow
      >
        <div style={{ display: 'inline-block' }}>
          <IconButton
            aria-label="Register User to Advisor Vision"
            size="small"
            color="primary"
            title="Register Professional"
            disabled={userEmailDomain !== adminEmailDomain}
            onClick={() => handleOpenRegisterDialog(row)}
          >
            <PersonAddOutlinedIcon fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>
    </>
  );
};


export const getColumnDef = (isAdmin: boolean, handleOpenDeleteDialog: OpenDeleteDialogFn, handleOpenRegisterDialog: RegisterProfessionalFn, professional: Professional) => [
  {
    id: "first_name",
    label: "Name",
    sortable: true,
    displayFn: (_v: any, row: Professional) => (<Typography>{`${row.first_name ?? ""} ${row.last_name ?? ""}`}</Typography>),
  },
  {
    id: "phone",
    label: "Phone",
    sortable: true,
  },
  {
    id: "email",
    label: "Email",
    sortable: true,
    displayFn: (v: string) => <MuiLink href={`mailto:${v}`}>{v}</MuiLink>,
  },
  {
    id: "status",
    label: isAdmin ? ("Advisor Vision Access") : (""),
    sortable: true,
    displayFn: (v: boolean, row: Professional) => isAdmin ? (displayStatusFn(v, row, handleOpenRegisterDialog, professional)) : null,
  },
  {
    id: "actions",
    label: isAdmin ? ("Actions") : (""),
    displayFn: (_v: any, row: Professional) =>
      isAdmin ? (
        <>
          {/* commented out so that we can release, but this will be the edit button eventually */}
          {/* <IconButton
        size="large"
        onClick={() => null}
        title="Edit Professional"
        >
          <EditOutlinedIcon color="primary" />
        </IconButton> */}

          < IconButton
            sx={{ fontFamily: "inherit" }}
            size="small"
            onClick={() => handleOpenDeleteDialog(row.id)}
            title="Delete Professional"
          >
            <ClearIcon />
          </IconButton>
        </>
      ) : null,
  },
];

export const FirmPage: FC = () => {
  const { advisory, professionalsList, setProfessionalsList, countries, setCountries, phoneTypes, setPhoneTypes, professional } = useProfessionals();
  const { setSuccess, setLoading, setError } = useUI();
  const [showAddProfessionalModal, setShowAddProfessionalModal] = useState(false);

  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [professionalToDelete, setProfessionalToDelete] = useState<number | null>(null);

  const [showRegisterConfirmationDialog, setShowRegisterConfirmationDialog] = useState(false);
  const [professionalToRegister, setProfessionalToRegister] = useState<Professional | null>(null);

  const { user } = useAuth();
  const isAdmin = !!user && professionalsList.some((professional) => professional.id === user.id && professional.admin);
  const [clientOccurrenceMap, setClientOccurrenceMap] = useState<Record<number, number>>({});
  const [reassignData, setReassignData] = useState<ReassignData>({
    needReassignCount: 0,
    reassignOptions: []
  });

  const [searchFilter, setSearchFilter] = useState("");

  useInit(async () => {
    if (countries.length && phoneTypes.length) {
      return;
    }
    const resp = await apiClient.get("/customers/phone-options");
    setCountries(resp.countries);
    setPhoneTypes(resp.phoneNumberTypes);
    refreshProfessionalData();
  }, { signOutOnError: false });

  const filteredRows = useMemo(() => {
    return professionalsList
      .filter(
        (r) =>
          r.first_name?.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()) ||
          r.last_name?.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()) ||
          r.email?.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
      )
      .sort((a, b) => a.first_name?.localeCompare(b.first_name));
  }, [professionalsList, searchFilter]);

  const address = advisory
    ? `${advisory.street_1 ?? ""} ${advisory.street_2 ?? ""} ${advisory.city_name ?? ""
    }${advisory.city_name ? "," : ""} ${advisory.state_name ?? ""} ${advisory.zip_code ?? ""
    }`
    : "";

  const buildClientOccurrenceMap = useCallback((list: typeof professionalsList) => {
    const map: Record<number, number> = {};
    list.forEach((p) => {
      p.clients.forEach((c) => {
        map[c.id] = (map[c.id] || 0) + 1;
      });
    });
    setClientOccurrenceMap(map);
  }, []);

  const refreshProfessionalData = useCallback(async () => {
    try {
      setLoading(true);
      const resp = advisory &&
        (await apiClient.get("/professionals/advisories/:p0/professionals", {
          routeParams: [advisory.id],
        }));
      if (resp) {
        setProfessionalsList(resp.professionalsList);
        buildClientOccurrenceMap(resp.professionalsList);
      }
      resp && setProfessionalsList(resp.professionalsList);
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to load your data after updating the professionals.
            Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      );
    } finally {
      setLoading(false);
    }
  }, [advisory, setProfessionalsList, setLoading, setError, buildClientOccurrenceMap]);

  const handleAddProfessional = useCallback(() => {
    setShowAddProfessionalModal(true);
  }, []);

  const closeAddProfessionalModal = async (success?: boolean) => {
    setShowAddProfessionalModal(false);
    if (!success) return;
    refreshProfessionalData();
  };

  const computeClientsNeedingReassignment = useCallback((profId: number) => {
    const theProfessional = professionalsList.find((p) => p.id === profId);
    if (!theProfessional) {
      return { needReassignCount: 0, reassignOptions: [] };
    }

    const clientsNeedingReassign = theProfessional.clients.filter(
      (c) => clientOccurrenceMap[c.id] === 1
    );

    const needReassignCount = clientsNeedingReassign.length;

    let reassignOptions: { value: string; label: string }[] = [];
    if (needReassignCount > 0) {
      reassignOptions = professionalsList
        .filter((p) => p.id !== profId)
        .map((p) => ({
          value: String(p.id),
          label: `${p.first_name} ${p.last_name}`,
        }));
    }
    return { needReassignCount, reassignOptions };
  }, [professionalsList, clientOccurrenceMap]);

  const handleOpenDeleteDialog = useCallback((professionalId: number) => {
    setProfessionalToDelete(professionalId);
    const data = computeClientsNeedingReassignment(professionalId);
    setReassignData(data);
    setShowDeleteConfirmDialog(true);
  }, [computeClientsNeedingReassignment]);

  const handleCloseDeleteDialog = (confirmed: boolean, reassignValue?: string | number) => {
    if (confirmed) {
      handleConfirmDelete(reassignValue);
    }
    setShowDeleteConfirmDialog(false);
  };

  const handleConfirmDelete = useCallback(async (reassignValue?: string | number) => {
    if (professionalToDelete !== null) {
      try {
        setLoading(true);
        let totalClientsReassigned = 0;

        if (reassignValue && reassignData.needReassignCount > 0) {
          const payload = {
            userProfessionalId: professionalToDelete,
            userAdvisoryId: advisory?.id!,
            professionalsToLink: [+reassignValue],
          }
          const reassignResp = await apiClient.post(`/professionals/interested-parties/grant-access`, { data: payload });

          totalClientsReassigned = reassignResp.professionalsLinked.reduce(
            (acc: number, linkedItem: { professionalId: number; count: number }) =>
              acc + linkedItem.count,
            0
          );
        }
        await apiClient.patch(`/professionals/:p0`, {
          routeParams: [professionalToDelete],
          data: { deleted: true },
        });

        let successMessage: string;
        if (reassignValue && reassignData.needReassignCount > 0) {
          successMessage = `Professional deleted successfully, ` +
            `${totalClientsReassigned} client${totalClientsReassigned === 1 ? "" : "s"
            } reassigned.`;
        } else {
          successMessage = `Professional deleted successfully.`;
        }
        setSuccess(successMessage);
        refreshProfessionalData();
      } catch (error) {
        setError(
          <div>
            <Typography variant="h6">Deletion Error:</Typography>
            <Typography variant="body1">
              We were unable to delete the professional. Please contact{" "}
              <ErrorContactEmail /> for assistance.
            </Typography>
          </div>
        );
      } finally {
        setLoading(false);
      }
    }
  }, [professionalToDelete, setLoading, setError, refreshProfessionalData, setSuccess, advisory, reassignData]);

  const handleOpenRegisterDialog = useCallback((professional: Professional) => {
    setProfessionalToRegister(professional);
    setShowRegisterConfirmationDialog(true);
  }, []);

  const handleCloseRegisterDialog = useCallback(async (confirmed?: boolean) => {
    if (confirmed && professionalToRegister) {
      await handleRegisterProfessional(professionalToRegister);
    }
    setShowRegisterConfirmationDialog(false);
    setProfessionalToRegister(null);
  }, [professionalToRegister]);

  const handleRegisterProfessional = useCallback(async (row: Professional) => {
    const phoneNumber = `+${row?.dial_code}${row.phone.replaceAll('-', '')}`;

    try {
      setLoading(true);
      const payload = {
        first_name: row?.first_name,
        username: row?.email,
        email: row?.email,
        phone_number: phoneNumber,
        professional_id: String(row?.id),
      }

      const response = await apiClient.post('/professionals/advisor-vision-register', { data: payload });
      if (response?.name === "UsernameExistsException") {
        setError(
          <div><Typography variant="h6">Registration Error:</Typography><Typography variant="body1">              That professional is already registered in Advisor Vision.            </Typography></div>
        );
        return; // bail out
      }

      setSuccess("Professional registered successfully");

      refreshProfessionalData();

    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Registration Error:</Typography>
          <Typography variant="body1">We could not register this professional for Advisor Vision. Please contact <ErrorContactEmail /> for assistance. </Typography></div>
      );
    } finally {
      setLoading(false);
    }
  }, [setLoading, setError, setSuccess, refreshProfessionalData]);

  const columnDef = useMemo(() =>
    professional ? getColumnDef(isAdmin, handleOpenDeleteDialog, handleOpenRegisterDialog, professional) : [],
    [isAdmin, handleOpenDeleteDialog, handleOpenRegisterDialog, professional]
  );

  const dialogTitle = reassignData.needReassignCount > 0 ? "Reassign Clients before Deleting" : "Delete Professional";
  const labelText = reassignData.needReassignCount > 0 ? `This professional has ${reassignData.needReassignCount} clients assigned with no additional Professionals from your advisory` : "";
  const subLabelText = reassignData.needReassignCount > 0 ? "*Please reassign these clients to another professional before proceeding." : undefined;

  const resourceToReassign = {
    identifierName: professionalsList.find((p) => p.id === professionalToDelete)
      ? (
        professionalsList.find((p) => p.id === professionalToDelete)!.first_name +
        " " +
        professionalsList.find((p) => p.id === professionalToDelete)!.last_name
      ).trim()
      : "",
    identifierLabel: "Professional",
    labelText,
    subLabelText,
    reassignOptions: reassignData.reassignOptions,
    emptyLabelText: "",
  };

  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground >
        <Toolbar sx={{ flexDirection: 'column', alignItems: 'flex-start', pt: 2, pb: 2 }}>
          <Breadcrumbs sx={{ pb: 1 }}>
            <MuiLink component={Link} to="/settings">Settings</MuiLink>
            <Typography>Firm</Typography>
          </Breadcrumbs>
          <Typography variant="h5" >{advisory?.name ?? ""}</Typography>
          <AddressLine display="flex" alignItems='center' mb={1}>
            <LocationOn fontSize="small" sx={{ mr: 1 }} />
            <Typography color={grey[600]}>{`Corporate Address: ${address}`}</Typography>
          </AddressLine>
        </Toolbar>
        <Box p={3}>
          <Box component="section" mb={1}>
            <Box sx={{ justifyContent: "space-between", display: 'flex', mb: 1 }}>
              <Typography variant="h5" mb={1}>Professionals</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {isAdmin && (
                  <Button color="info" onClick={handleAddProfessional} startIcon={<AddIcon />} sx={{ mr: 2 }}>
                    Add Professional
                  </Button>
                )}
                <TextField
                  value={searchFilter}
                  onChange={(event) => setSearchFilter(event.target.value)}
                  placeholder="Search Professionals"
                  size="small"
                  helperText="" //Empty string to remove the helper space padding, since we've set our theme's default helperText value as " ";
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <DataSourceTable columnDef={columnDef} dataSource={filteredRows} emptyMessage="No professionals" />
            </TableContainer>
          </Box>
        </Box>
        <Dialog
          open={showAddProfessionalModal}
          onClose={() => closeAddProfessionalModal()}
          fullWidth
          maxWidth="sm"
        >
          <AdminAddProfDialog onClose={closeAddProfessionalModal} />
        </Dialog>
        <ResourceReassignmentConfirmationDialog
          open={showDeleteConfirmDialog}
          dialogTitle={dialogTitle}
          resourceToReassign={resourceToReassign}
          handleClose={handleCloseDeleteDialog}
          confirmButtonLabel="Yes, I confirm"
          cancelButtonLabel="Cancel"
          {...{
            TransitionProps: {
              onExited: () => setProfessionalToDelete(null),
            },
          }}
        />
        <ConfirmationDialog
          open={showRegisterConfirmationDialog}
          handleClose={handleCloseRegisterDialog}
          titleNode="Registration Confirmation"
          yesButtonLabel="Confirm"
          yesButtonColor='info'
          noButtonLabel="Cancel"
          message={
            professionalToRegister ? 'Please confirm that you would like to register ' + professionalToRegister.first_name + ' ' + professionalToRegister.last_name + ' to Advisor Vision.' : ''
          }
        ></ConfirmationDialog>
      </ContentBackground>
    </Container>
  );
};