import React, { createContext, FC, PropsWithChildren, useMemo, useState } from "react";
import { Advisory, Professional, ProfessionalType } from "../api/payloads";
import { Country, PhoneNumberType } from "../api/payloads/customer.payload";

export type ProfessionalsContextValue = {
  advisory?: Advisory,
  professional?: Professional,
  setProfessional: React.Dispatch<React.SetStateAction<Professional | undefined>>
  professionalsList: (Professional & { active_clients_count: number, clients: { id: number; name: string }[], status: boolean })[],
  setAdvisory: React.Dispatch<React.SetStateAction<Advisory | undefined>>,
  setProfessionalsList: React.Dispatch<React.SetStateAction<(Professional & { active_clients_count: number, clients: { id: number; name: string }[], status: boolean })[]>>,
  countries: Country[],
  professionalTypes: ProfessionalType[],
  setProfessionalTypes: React.Dispatch<React.SetStateAction<ProfessionalType[]>>,
  setCountries: React.Dispatch<React.SetStateAction<Country[]>>,
  phoneTypes: PhoneNumberType[],
  setPhoneTypes: React.Dispatch<React.SetStateAction<PhoneNumberType[]>>,
}

export const ProfessionalsContext = createContext(undefined as unknown as ProfessionalsContextValue);

export const ProfessionalsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [professional, setProfessional] = useState<ProfessionalsContextValue['professional']>();
  const [advisory, setAdvisory] = useState<ProfessionalsContextValue['advisory']>();
  const [professionalsList, setProfessionalsList] = useState<ProfessionalsContextValue['professionalsList']>([]);
  const [professionalTypes, setProfessionalTypes] = useState<ProfessionalsContextValue['professionalTypes']>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [phoneTypes, setPhoneTypes] = useState<PhoneNumberType[]>([]);

  const value = useMemo(() => ({
    advisory,
    professional,
    setProfessional,
    professionalsList,
    setAdvisory,
    setProfessionalsList,
    countries,
    setCountries,
    phoneTypes,
    setPhoneTypes,
    professionalTypes,
    setProfessionalTypes,
  }), [advisory, professional, professionalsList, countries, phoneTypes, professionalTypes]);

  return (
    <ProfessionalsContext.Provider value={value}>
      {children}
    </ProfessionalsContext.Provider>
  )
}